import { svgComponent } from "../svgComponent";
import facebookSVG from "./facebook.svg";
import linkedinSVG from "./linkedin.svg";
import instagramSVG from "./instagram.svg";
import youtubeSVG from "./youtube.svg";
import whatsAppInkIcon from "./whatsappInk.svg";
import facebookInkIcon from "./facebookInk.svg";
import linkedinInkIcon from "./linkedinInk.svg";
import emailInkIcon from "./emailInk.svg";
import winnerIcon from "./winner.svg";

export const FacebookIcon = (props) =>
  svgComponent({ SVG: facebookSVG, alt: "facebookIcon", ...props });
export const LinkedinIcon = (props) =>
  svgComponent({ SVG: linkedinSVG, alt: "linkedinIcon", ...props });
export const InstagramIcon = (props) =>
  svgComponent({ SVG: instagramSVG, alt: "instagramIcon", ...props });
export const YouTubeIcon = (props) =>
  svgComponent({ SVG: youtubeSVG, alt: "youtubeIcon", ...props });
export const WhatsAppInkIcon = (props) =>
  svgComponent({ SVG: whatsAppInkIcon, alt: "whatsAppInkIcon", ...props });
export const LinkedinInkIcon = (props) =>
  svgComponent({ SVG: linkedinInkIcon, alt: "LinkedinInkIcon", ...props });
export const FacebookInkIcon = (props) =>
  svgComponent({ SVG: facebookInkIcon, alt: "FacebookInkIcon", ...props });
export const EmailInkIcon = (props) =>
  svgComponent({ SVG: emailInkIcon, alt: "EmailInkIcon", ...props });

export const WinnerIcon = (props) =>
  svgComponent({ SVG: winnerIcon, alt: "WinnerIcon", ...props });
