export const svgComponent = ({ SVG, alt, ...props }) => (
  <img src={SVG} alt={alt} {...props} />
);

export const svgJs = {
  WinnerIconJs: (
    <svg width="62px" height="62px" viewBox="0 0 120 120">
      <g>
        <polygon
          className="st0"
          style={{ fill: "#E24255" }}
          points="79.7,45.6 60,55.5 40.3,45.6 15.9,94.3 31.1,92.8 38.9,105.9 60,63.9 81.1,105.9 88.9,92.8 104.1,94.3"
        />
        <circle
          className="st1"
          style={{ fill: "#FFC54D" }}
          cx="60"
          cy="46.4"
          r="32.2"
        />
        <circle
          className="st2"
          style={{ fill: "#E8B04B" }}
          cx="60"
          cy="46.4"
          r="25.3"
        />
        <path
          className="st3"
          style={{ fill: "#FFFFFF" }}
          d="M61.2,31.2l4.2,8.4c0.2,0.4,0.6,0.7,1,0.8l9.3,1.4c1.1,0.2,1.6,1.5,0.8,2.3l-6.7,6.6c-0.3,0.3-0.5,0.8-0.4,1.2   l1.6,9.3c0.2,1.1-1,2-2,1.4l-8.3-4.4c-0.4-0.2-0.9-0.2-1.3,0L51,62.6c-1,0.5-2.2-0.3-2-1.4l1.6-9.3c0.1-0.4-0.1-0.9-0.4-1.2   l-6.7-6.6c-0.8-0.8-0.4-2.2,0.8-2.3l9.3-1.4c0.4-0.1,0.8-0.3,1-0.8l4.2-8.4C59.3,30.2,60.7,30.2,61.2,31.2z"
        />
      </g>
    </svg>
  ),
  Conference: (
    <svg
      width="62px"
      height="62px"
      viewBox="0 0 48 48"
      enable-background="new 0 0 48 48"
    >
      <circle fill="#FFA726" cx="12" cy="21" r="5" />
      <g fill="#455A64">
        <path d="M2,34.7c0,0,2.8-6.3,10-6.3s10,6.3,10,6.3V38H2V34.7z" />
        <path d="M46,34.7c0,0-2.8-6.3-10-6.3s-10,6.3-10,6.3V38h20V34.7z" />
      </g>
      <circle fill="#FFB74D" cx="24" cy="17" r="6" />
      <path
        fill="#607D8B"
        d="M36,34.1c0,0-3.3-7.5-12-7.5s-12,7.5-12,7.5V38h24V34.1z"
      />
      <circle fill="#FFA726" cx="36" cy="21" r="5" />
      <circle fill="#FFA726" cx="12" cy="21" r="5" />
      <circle fill="#FFA726" cx="36" cy="21" r="5" />
    </svg>
  ),
  Lecturer: (
    <svg width="48px" height="48px" viewBox="0 0 512.001 512.001">
      <path
        style={{ fill: "#FBA218" }}
        d="M338.778,270.449c0.924,7.094,1.401,14.328,1.401,21.674c0,91.767-74.392,166.16-166.16,166.16  C82.252,458.282,7.86,383.89,7.86,292.122c0-91.767,74.392-166.159,166.16-166.159c48.662,0,92.438,20.918,122.827,54.254"
      />
      <path
        style={{ fill: "#35DCC3" }}
        d="M426.678,270.449H296.833V131.182c0-42.782,34.681-77.463,77.463-77.463h52.381  c42.782,0,77.463,34.681,77.463,77.463v61.804C504.14,235.768,469.459,270.449,426.678,270.449z"
      />
      <path
        style={{ fill: "#027EA8" }}
        d="M428.232,52.673c-4.045-0.569-7.924-2.976-12.126-2.976h-31.238  c-48.62,0-89.134,43.437-89.134,92.058v133.253h56.59V141.753C352.323,97.336,385.474,58.69,428.232,52.673z"
      />
      <path
        style={{ fill: "#F14507" }}
        d="M99.705,292.122c0-75.883,50.923-139.751,120.421-159.634c-14.644-4.223-30.102-6.526-46.107-6.526  c-91.768,0-166.16,74.392-166.16,166.159s74.392,166.16,166.16,166.16c15.95,0,31.322-2.378,45.922-6.574  C150.538,431.767,99.705,367.94,99.705,292.122z"
      />
      <g>
        <path
          style={{ fill: "#FFFFFF" }}
          d="M267.936,322.164c0,48.206-42.048,93.917-93.917,93.917s-93.917-45.711-93.917-93.917   L267.936,322.164L267.936,322.164z"
        />
        <circle
          style={{ fill: "#FFFFFF" }}
          cx="239.038"
          cy="234.323"
          r="21.673"
        />
        <circle
          style={{ fill: "#FFFFFF" }}
          cx="102.982"
          cy="234.323"
          r="21.673"
        />
      </g>
      <path d="M174.019,423.416c-26.828,0-52.274-11.101-71.902-31.257c-18.898-19.407-29.598-45.11-29.598-70.519v-7.86h203.303v7.86  c0,25.409-10.852,51.112-29.749,70.519C226.445,412.316,200.847,423.416,174.019,423.416z M88.374,329.499  c1.951,18.734,10.772,37.218,24.867,51.692c16.644,17.092,38.228,26.505,60.778,26.505s44.135-9.413,60.778-26.505  c14.095-14.475,22.917-32.958,24.867-51.692H88.374z" />
      <path d="M239.038,263.861c-16.284,0-29.532-13.248-29.532-29.532s13.248-29.533,29.532-29.533c16.285,0,29.533,13.248,29.533,29.533  C268.572,250.612,255.324,263.861,239.038,263.861z M239.038,220.514c-7.617,0-13.813,6.197-13.813,13.814  c0,7.617,6.197,13.813,13.813,13.813c7.618,0,13.814-6.197,13.814-13.813C252.852,226.711,246.656,220.514,239.038,220.514z" />
      <path d="M102.986,263.861c-16.284,0-29.532-13.248-29.532-29.532s13.248-29.533,29.532-29.533s29.532,13.248,29.532,29.533  C132.518,250.612,119.27,263.861,102.986,263.861z M102.986,220.514c-7.617,0-13.813,6.197-13.813,13.814  c0,7.617,6.197,13.813,13.813,13.813c7.617,0,13.813-6.197,13.813-13.813C116.799,226.711,110.602,220.514,102.986,220.514z" />
      <path d="M425.824,45.859H375.15c-47.517,0-86.176,38.659-86.176,86.176v29.466c-31.6-27.768-72.54-43.399-114.954-43.399  C78.065,118.103,0,196.167,0,292.122s78.065,174.02,174.019,174.02c95.955,0,174.02-78.065,174.02-174.02  c0-4.604-0.204-9.222-0.564-13.813h78.349c47.517,0,86.176-38.659,86.176-86.176v-60.097C512,84.518,473.342,45.859,425.824,45.859z   M332.32,292.122c0,87.287-71.014,158.301-158.301,158.301c-87.286,0-158.3-71.014-158.3-158.301s71.014-158.301,158.3-158.301  c43.414,0,85.13,18.004,114.954,49.484v95.003h42.732C332.102,282.895,332.32,287.516,332.32,292.122z M496.281,192.132  c0,38.85-31.607,70.457-70.457,70.457H304.693V132.035c0-38.85,31.607-70.457,70.457-70.457h50.674  c38.85,0,70.457,31.607,70.457,70.457V192.132z" />
      <g>
        <rect
          x="352.322"
          y="90.565"
          style={{ fill: "#FFFFFF" }}
          width="95.364"
          height="15.719"
        />
        <rect
          x="335.555"
          y="123.051"
          style={{ fill: "#FFFFFF" }}
          width="128.898"
          height="15.719"
        />
        <rect
          x="335.555"
          y="153.442"
          style={{ fill: "#FFFFFF" }}
          width="128.898"
          height="15.719"
        />
        <rect
          x="335.555"
          y="185.929"
          style={{ fill: "#FFFFFF" }}
          width="128.898"
          height="15.719"
        />
        <rect
          x="335.555"
          y="218.415"
          style={{ fill: "#FFFFFF" }}
          width="89.076"
          height="15.719"
        />
      </g>
      <path d="M181.244,298.076h-14.449c-12.301,0-22.309-10.008-22.309-22.309h15.719c0,3.633,2.956,6.59,6.59,6.59h14.449  c3.633,0,6.59-2.956,6.59-6.59h15.719C203.553,288.068,193.545,298.076,181.244,298.076z" />
    </svg>
  ),
};
