import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
} from "../elements/Icon/svgIcons";

const socialsIconLinks = [
  {
    href: "https://www.facebook.com/workesergonomia",
    icon: <FacebookIcon />,
  },
  {
    href: "https://www.linkedin.com/company/workes-ergonomia/",
    icon: <LinkedinIcon />,
  },
  {
    href: "https://www.instagram.com/aureadeponte",
    icon: <InstagramIcon />,
  },
];

export { socialsIconLinks };
