import BannerSectionImg from "../../../ui/assets/colagem_photos_banner_2_1305x322.png";

import "./styles.css";

export const BannerSection = ({ title, bannerImg }) => (
  <section className="banner-section">
    <div className="internal-banner">
      <img
        src={bannerImg ?? BannerSectionImg}
        className="image"
        alt="Ergometria"
        title="Ergometria"
      />

      <div className="container description-banner -internal -alpha">
        <div className="description">
          <h1 className="title">{title ?? "Workes"}</h1>
        </div>
      </div>
    </div>
  </section>
);
