import { useEffect, useState } from "react";
import { NavLinks } from "../../../components/NavLinks/NavLinks";
import "./dropdownMenuBar.css";

export const DropdownMenuBar = ({ isMenuFixed, onClick }) => {
  const [linksAppearenceStyles, setLinksAppearenceStyles] = useState(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLinksAppearenceStyles(() => ({
        backgroundColor: "#fff",
        transition: "background 120ms",
      }));
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div
      className="dropdown-wrapper"
      style={
        isMenuFixed
          ? { top: "122px", ...linksAppearenceStyles }
          : { margin: "0 30px", ...linksAppearenceStyles }
      }
    >
      <ul className="menu" onClick={onClick}>
        <NavLinks htmlTag="li" className="menu-bar-item" />
      </ul>
    </div>
  );
};
