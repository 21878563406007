import { useState } from "react";
import { NavLink } from "react-router-dom";
import { DropdownMenuBar } from "./DropdownMenuBar";
import workesLogo from "../../../../ui/assets/workes_logo.png";
import { NavLinks } from "../../../components/NavLinks/NavLinks";

export const MenuBar = ({ isMenuFixed, isLg }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <nav className="menu-bar" style={isMenuFixed ? stickyStyles : null}>
      <NavLink to="/">
        <img src={workesLogo} alt="Workes Ergometria" width={140} height={60} />
      </NavLink>
      <ul className="menu-bar-items desktop-only">
        <NavLinks htmlTag="li" className="menu-bar-item" />
      </ul>
      <button
        type="button"
        className="menu-toggle mobile-only"
        onClick={() => setDropdownOpen((prev) => !prev)}
      >
        <span className="sr-only">Menu mobile</span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
      </button>

      {dropdownOpen && !isLg && (
        <DropdownMenuBar
          isOpen={dropdownOpen}
          isMenuFixed={isMenuFixed}
          onClick={() => setDropdownOpen(false)}
        />
      )}
    </nav>
  );
};

const stickyStyles = {
  position: "fixed",
  top: 36,
  left: 0,
  right: 0,
  width: "100%",
  maxWidth: 1400,
  backgroundColor: "#fefefe",
  boxShadow: "0 3px 2px 0 rgba(127, 103, 157, 0.28)",
  zIndex: 4,
  transition: "all 0.5s ease-in-out",
};
