import leftSideImage2 from "../../../../assets/home-page-image-left-side-2.png";
import leftSideImage from "../../../../assets/home-page-image-left-side.png";
import "./styles.css";

export const Content = () => (
  <section>
    <div className="container">
      <article className="internal-content">
        <h1 style={{ textAlign: "center" }}>Ergonomia e Saúde Ocupacional</h1>
        <p>
          Empresa especializada em consultoria e assessoria em
          <strong> Ergonomia e Saúde Ocupacional</strong>, que tem por objetivo
          oferecer assistência ao cumprimento das Normas Regulamentadoras,
          perante a exigência da lei e a fiscalização do Ministério do Trabalho,
          bem como auxílio na prevenção de processos trabalhistas, por meio da
          identificação e controle de acidentes, doenças ocupacionais e
          desenvolvimento da <strong>qualidade de vida no trabalho.</strong>
        </p>

        <div className="content-section">
          <div className="image-and-text">
            <div className="image-and-text-left">
              <img
                src={leftSideImage2}
                alt="Trabalho Workes"
                width={260}
                height={200}
              />
            </div>

            <div className="image-and-text-right">
              <p>
                Fundada no ano de 2003, pela Profa. Ms. Áurea de Ponte -
                Fisioterapeuta e Ergonomista, certificada em Ergonomics and
                Human Factors:{" "}
                <b>
                  Strategic Solutions for Workplace Safety and Healthy pela
                  Universidade de Havard School of Public Health
                </b>
                .
              </p>
            </div>
          </div>

          <h1 style={{ textAlign: "center", margin: "20px 20px" }}>
            Somos uma empresa voltada para soluções em Ergonomia, e contamos com
            uma equipe de profissionais especializados nos serviços de:
          </h1>

          <div className="image-and-text">
            <div className="image-and-text-left">
              <ul className="list-site">
                <li>Análise Ergonômica do Trabalho (NR 17).</li>
                <li>Análise Preliminar de Risco Ergonômico (NR 01).</li>
                <li>Gestão em Ergonomia.</li>
                <li>Formação de Comitê em Ergonomia.</li>
                <li>Fisioterapia do Trabalho.</li>
                <li>Ginástica Laboral.</li>
                <li>
                  Assistência Técnicas em Perícias e Elaboração de Laudos
                  Técnicos que vierem a ser exigidos nos autos de ações
                  trabalhistas.
                </li>
                <li>
                  Assistência Técnica ao Médico do Trabalho da empresa no
                  estabelecimento no Nexo Técnico Epidemiológico.
                </li>
                <li>Treinamentos in Company.</li>
                <li>
                  Laudo de Acessibilidade (deficientes físicos, auditivos e
                  visuais).
                </li>
                <li>Certificação em Auditorias.</li>
                <li>
                  Medições de Iluminamento (NHO11), Ruído e Temperatura,
                  conforme NR 17.
                </li>
                <li>Terceirização de mão de obra especializada.</li>
              </ul>
            </div>
            <div className="image-and-text-right">
              <img
                src={leftSideImage}
                alt="Trabalho Workes"
                width={410}
                height={620}
              />
            </div>
          </div>

          <div className="image-and-text ">
            <div className="image-and-text-left">
              <h1 style={{ textAlign: "center", margin: 12 }}>
                Somos Referencias em cursos práticos de ergonomia em todo Brasil
              </h1>
            </div>
            <div className="image-and-text-right">
              <p>
                Serviços de <b>COACHING EM ERGONOMIA</b>, onde nossa equipe de
                Mestres e Doutores Especialistas em Ergonomia, orienta a outros
                profissionais quanto a melhor solução para atendimento aos seus
                clientes, na prestação de serviços de Ergonomia e Saúde
                Ocupacional.
              </p>
            </div>
          </div>
        </div>
      </article>
    </div>
  </section>
);
