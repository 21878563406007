import {
  EmailInkIcon,
  FacebookInkIcon,
  LinkedinInkIcon,
  WhatsAppInkIcon,
} from "../../../elements/Icon/svgIcons";
import "./headerSocials.css";

export const HeaderSocials = ({ isMenuFixed }) => (
  <nav className="nav-top" style={isMenuFixed ? stickyStyles : null}>
    <ul>
      <li className="nav-top-item-group">
        <ul className="nav-top-item-group">
          <li className="nav-top-item phone" style={{ marginTop: 2.5 }}>
            <a href="tel:+55 35 99133-3828">(35) 99133-3828</a>
          </li>
        </ul>
      </li>
      <li className="nav-top-item-group">
        <ul className="nav-top-item-group">
          <li>
            <a href="https://wa.me/5535991333828">
              <WhatsAppInkIcon width={20} height={20} />
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/workesergonomia">
              <FacebookInkIcon width={20} height={20} />
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/workes-ergonomia/">
              <LinkedinInkIcon width={20} height={20} />
            </a>
          </li>
          <li>
            <a href="mailto:ergonomiaworkes@gmail.com">
              <EmailInkIcon width={26} height={26} style={{ marginTop: 2 }} />
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
);

const stickyStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  maxWidth: 1400,
  zIndex: 4,
  transition: "all 0.5s ease-in-out",
};
