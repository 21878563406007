import { BannerSection } from "../../../common/components/BannerSection/BannerSection";
import { ContentCarousel } from "../../../common/components/Carousel/Carousel";
import { PhotoGallery } from "../../../common/components/PhotoGallery/PhotoGallery";
import configData from "../../assets/images-config/galeryConfig.json";

import "./styles.css";

export const WorkGalery = () => (
  <>
    <BannerSection title="Galeria" />
    <section>
      <div className="container">
        <article className="internal-content">
          <ContentCarousel
            contentData={configData}
            styles={{ margin: "30px 0", width: "100%" }}
            interval={2000}
            indicators
            fade
          />
          <PhotoGallery contentData={configData} />
        </article>
      </div>
    </section>
  </>
);
