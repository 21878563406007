import { useWindowDimensions } from "../../screen/windowDimensions";
import { useWindowScrollListener } from "../../screen/windowScrollListener";
import { HeaderSocials } from "./components/HeaderSocials";
import { MenuBar } from "./components/MenuBar";
import "./styles.css";

const TopSpaceHolder = ({ isLg }) => (
  <div style={{ height: isLg ? 168 : 148 }}></div>
);

export const Header = () => {
  const isScrolledAbove130 = useWindowScrollListener();
  const { windowWidth } = useWindowDimensions();

  const isLg = windowWidth >= 1024;
  const isLSm = windowWidth >= 524;
  const isAbove354 = windowWidth >= 354;
  return (
    <header className="header">
      <HeaderSocials
        isMenuFixed={isScrolledAbove130}
        isLSm={isLSm}
        isAbove354={isAbove354}
      />
      <MenuBar isMenuFixed={isScrolledAbove130} isLg={isLg} />
      {isScrolledAbove130 && (
        <TopSpaceHolder show={isScrolledAbove130} isLg={isLg} />
      )}
    </header>
  );
};
