import { Home } from "./Home/Home";
import { WorkGalery } from "./WorkGalery/WorkGalery";
import { Companies } from "./Companies/Companies";
import { Contact } from "./Contact/Contact";

export { ErrorPage } from "./ErrorPage/ErrorPage";

export const Components = [
  { element: Home, name: "Home", title: "Workes", path: "/" },
  {
    element: WorkGalery,
    name: "WorkGalery",
    title: "Galeria",
    path: "/galeria-trabalhos",
  },
  {
    element: Companies,
    name: "Companies",
    title: "Clientes",
    path: "/empresas",
  },
  { element: Contact, name: "Contact", title: "Contato", path: "/contato" },
];
