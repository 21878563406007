import "./styles.css";
import { Link } from "react-router-dom";

import { NavLinks } from "../../components/NavLinks/NavLinks";

import workesLogo from "../../../ui/assets/workes_logo.png";

import { socialsIconLinks } from "../../constants/constants";

export const Footer = () => (
  <footer className="footer">
    <section className="inner">
      <div className="container">
        <Link to="/">
          <img
            src={workesLogo}
            className="footer-logo"
            alt="Workes Ergometria"
          />
        </Link>

        <div className="links">
          <div className="menus">
            <h2>Menu</h2>
            <nav className="nav">
              <ul className="menu">
                <NavLinks htmlTag="li" className="menu-bar-item" />
              </ul>
            </nav>
          </div>

          <div className="contacts">
            <div>
              <h2>Contato</h2>
              <a
                href="tel:+55 35 99133-3828"
                style={{ textDecoration: "underline" }}
              >
                (35) 99133-3828
              </a>
            </div>

            <div>
              <h2>Siga a Workes</h2>
              <nav className="social">
                {socialsIconLinks.map((social, index) => (
                  <a
                    key={index}
                    target="_blank"
                    rel="noreferrer"
                    href={social.href}
                  >
                    {social.icon}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="copyright">
      <div className="container">
        <div className="copyright-menu">
          <span>Prof. Ms. Áurea de Ponte - Fisioterapeuta e Ergonomista</span>
        </div>
        <div className="copyright-menu">
          <strong>
            © {new Date().getFullYear().toString()}{" "}
            <a href="https://allanstocco.com/" target="_blank" rel="noreferrer">
              ASR
            </a>
            . Todos os direitos reservados
          </strong>
        </div>
      </div>
    </section>
  </footer>
);
