import { Layout } from "./common/layout/Layout";

import { ScrollToTop } from "./common/screen/scrollToTop";

import { ErrorPage, Components } from "./ui/pages";

const routers = [
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: Components.map((config) => ({
      ...config,
      element: (
        <ScrollToTop>
          <config.element />
        </ScrollToTop>
      ),
    })),
  },
];

export default routers;
