import { NavLink } from "react-router-dom";
import { StyledAnchorButton } from "../../../common/elements/StyledAnchorButton/StyledAnchorButton";
import workesLogo from "../../assets/workes_logo.png";

const styles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: 20,
  width: "100%",
  height: "100vh",
  backgroundColor: "#222",
};

export const ErrorPage = () => (
  <div style={styles}>
    <img src={workesLogo} alt="Workes" />
    <h3>Página não encontrada</h3>
    <StyledAnchorButton
      bg="purple"
      Component={
        <NavLink to="/" style={{ color: "#fff" }}>
          Página Inicial
        </NavLink>
      }
    />
  </div>
);
