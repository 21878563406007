import { BannerSection } from "../../../common/components/BannerSection/BannerSection";
import { PhotoGallery } from "../../../common/components/PhotoGallery/PhotoGallery";
import companyLogos from "./configData";

export const Companies = () => (
  <>
    <BannerSection title="Clientes" />
    <section>
      <div className="container">
        <article className="internal-content">
          <PhotoGallery
            imageList={companyLogos}
            imgStyles={{
              padding: 4,
              border: "1px solid rgba(194, 18, 146, 0.15)",
              objectFit: "contain",
            }}
          />
        </article>
      </div>
    </section>
  </>
);
