import { useState } from "react";
import "./StyledAnchorButton.css";

export const StyledAnchorButton = ({ title, href, bg, style, Component }) => {
  const [bgColorProps, setBgColorProps] = useState(bg);
  const elementProps = bg && {
    style: {
      backgroundColor: bgColorProps?.bg ?? bg,
      opacity: bgColorProps?.opacity,
      ...style,
    },
    onMouseEnter: () => setBgColorProps({ bg, opacity: 0.75 }),
    onMouseLeave: () => setBgColorProps({ bg, opacity: 1 }),
  };

  if (Component) {
    return (
      <div className="btn-wpp" {...elementProps}>
        {Component}
      </div>
    );
  }

  return (
    <a className="btn-wpp" href={href} {...elementProps}>
      {title}
    </a>
  );
};
