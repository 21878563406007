import { useMemo } from "react";
import { breakPoints } from "./breakPoints";

export const useMediaIs = (breakPoint) =>
  window.innerWidth >= breakPoints[breakPoint];

export const isMediaLessThanXs = window.innerWidth < breakPoints["xs"];

export const useMediaBreakpoints = (xxsIncluded = false) => {
  const width = window.innerWidth;
  return useMemo(
    () => ({
      xs: !xxsIncluded || width >= breakPoints.xs,
      sm: width >= breakPoints.sm,
      md: width >= breakPoints.md,
      xmd: width >= breakPoints.xmd,
      lg: width >= breakPoints.lg,
      xlg: width >= breakPoints.xlg,
      xxl: width >= breakPoints.xxl,
    }),
    [width, xxsIncluded]
  );
};

export function useMediaSelect({
  xxs,
  xs,
  sm,
  md,
  xmd,
  lg,
  xlg,
  xxl,
  default: defaults,
} = {}) {
  const media = useMediaBreakpoints(true);
  if (media.xxl && xxl) {
    return xxl;
  }
  if (media.xlg && xlg) {
    return xlg;
  }
  if (media.lg && lg) {
    return lg;
  }
  if (media.xmd && xmd) {
    return xmd;
  }
  if (media.md && md) {
    return md;
  }
  if (media.sm && sm) {
    return sm;
  }
  if (media.xs && xs) {
    return xs;
  }
  return xxs || xs || defaults;
}
