import { Carousel, Image } from "react-bootstrap";

import { useWindowDimensions } from "../../screen/windowDimensions";

import { useMediaBreakpoints, useMediaSelect } from "../../screen/media";

export const ContentCarousel = ({
  contentData,
  styles,
  fade,
  controls = false,
  indicators,
  interval,
}) => {
  const { windowWidth } = useWindowDimensions();
  const resize = useMediaSelect({
    xs: 0.8,
    sm: 1.4,
    md: 1.4,
    lg: 2,
  });

  const { sm } = useMediaBreakpoints();

  const imageWidth = windowWidth / resize;
  const imageHeight = imageWidth / 1.5;

  if (!contentData) return null;

  return (
    <div style={styles}>
      <Carousel fade={fade} controls={controls} indicators={indicators}>
        {contentData.map((image, index) => (
          <Carousel.Item
            key={index}
            interval={interval}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Image
              src={image.source}
              width={imageWidth}
              height={imageHeight}
              alt=""
            />
            <Carousel.Caption>
              <div
                style={{ ...captionStyles.wrapper, width: imageWidth / 1.8 }}
              >
                <p
                  style={{
                    ...captionStyles.description,
                    fontSize: !sm ? 12 : null,
                  }}
                >
                  {image.description}
                </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

const captionStyles = {
  wrapper: {
    margin: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  description: {
    fontWeight: "bold",
    lineHeight: "normal",
    margin: 0,
  },
};
