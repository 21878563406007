import "./styles.css";
import { Image } from "react-bootstrap";
import { BannerSection } from "../../../common/components/BannerSection/BannerSection";

import { StyledAnchorButton } from "../../../common/elements/StyledAnchorButton/StyledAnchorButton";

import workesDiagram from "../../assets/diagram-workes.png";
import workesChar from "../../assets/workes_char.png";

export const Contact = () => (
  <>
    <BannerSection title="Contate Workes" />
    <section className="container">
      <div className="internal-content">
        <div className="unit-1">
          <Image className="workes-char" src={workesChar} alt="Workes Profa" />
          <div className="article-text-content">
            <h1 style={{ textAlign: "center" }}>
              ATENDEMOS EM TODO O TERRITÓRIO NACIONAL
            </h1>
            <div className="contact-wrapper">
              <div className="contact-unit">
                <h5>UNIDADE I</h5>
                <p>Rua Piauí, 756 - Centro | Poços de Caldas - MG</p>
                <p>
                  <a href="tel:+55 35 99133-3828">(35) 9.9133-3828</a>
                </p>
                <StyledAnchorButton
                  title="Chamar no Whatsapp"
                  href="https://wa.me/5535991333828"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="about-section-2">
      <div className="internal-content section-flex">
        <h1 style={{ color: "#000", textAlign: "center" }}>
          SOLICITE UM ORÇAMENTO PARA A SUA EMPRESA
        </h1>
        <StyledAnchorButton
          title="Orçamento"
          href="mailto:ergonomiaworkes@gmail.com"
          bg="orange"
        />
      </div>
    </section>
    <section className="container about-section-3">
      <div className="internal-content section-flex">
        <div className="section-3-content first">
          <Image
            className="section-3-img"
            src={workesDiagram}
            alt="Workes Diagram"
          />
        </div>
        <div className="section-3-content second">
          <h1>
            Saiba aqui como ser um Franqueado Workes Ergonomia, a primeira
            franquia especializada em Ergonomia do Brasil.
          </h1>
        </div>
      </div>
    </section>
  </>
);
