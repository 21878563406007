import "./styles.css";
import React, { useState } from "react";

import { Modal, Image } from "react-bootstrap";

import { useMediaIs, useMediaSelect } from "../../screen/media";

import { useWindowDimensions } from "../../screen/windowDimensions";

import { svgJs } from "../../elements/Icon/svgComponent";

export const PhotoGallery = ({ contentData, imageList, imgStyles }) => {
  const [modalShow, setModalShow] = useState(null);

  const { windowWidth } = useWindowDimensions();

  const sm = useMediaIs("sm");
  const gutter = useMediaSelect({
    xs: 16,
    sm: 16,
    md: 14,
    lg: 8,
    xlg: 10,
    xxl: 0,
    default: "0",
  });

  const columns = sm ? 8 : 4;
  const itemWidth = Math.floor(windowWidth / columns);
  const imageWidth = itemWidth - gutter;
  const imageHeight = imageWidth / 1.5;

  if (!contentData && !imageList) return null;

  const content = imageList ?? contentData;
  return (
    <>
      <div className="media-container">
        <div className="flex-wrapper">
          {content.map((image, index) => (
            <div
              key={index}
              className="image-wrapper"
              onClick={() => setModalShow(content[index])}
            >
              <Image
                src={image.source ?? image}
                className="image-item"
                width={imageWidth}
                height={imageHeight}
                style={imgStyles}
                alt=""
              />

              {image.icon && (
                <div style={captionStyles.svgBox}>{svgJs[image.icon]}</div>
              )}
            </div>
          ))}
        </div>
      </div>

      <Modal show={!!modalShow} size="md" centered>
        <Modal.Header
          closeButton
          onClick={() => setModalShow(null)}
        ></Modal.Header>
        <Modal.Body>
          <Image className="image-item" src={modalShow?.source ?? modalShow} />
          {modalShow?.description && (
            <div style={captionStyles.wrapper}>
              <p style={captionStyles.description}>{modalShow.description}</p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

const captionStyles = {
  wrapper: {
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    bottom: 0,
    width: "100%",
  },
  description: {
    color: "white",
    fontWeight: "bold",
    paddingTop: 6,
    paddingLeft: 14,
    paddingRight: 14,
  },
  svgBox: {
    position: "absolute",
    top: -20,
    right: -12,
  },
};
