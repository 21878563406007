import { createElement } from "react";
import { NavLink } from "react-router-dom";

import { Components } from "../../../ui/pages";

export const NavLinks = ({ htmlTag, className }) => {
  const DynamicTag = ({ tag, className, children, ...props }) =>
    createElement(tag, { className, ...props }, children);

  if (!htmlTag) {
    return null;
  }

  return Components.map((component, index) => (
    <DynamicTag key={index} tag={htmlTag} className={className}>
      <NavLink to={component.path}>{component.title}</NavLink>
    </DynamicTag>
  ));
};
