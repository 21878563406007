import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollToTop = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  return children;
};

export const handleScrollToTop = (top, behavior) => {
  window.scrollTo({
    top: top ?? 0,
    behavior: behavior ?? "smooth",
  });
};
